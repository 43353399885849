import Api from './Api.js';
const organization_id = () => {
  return window.localStorage.getItem("OrganizationID");
};

export default {
  addCategory(payload) {
    return Api().post(`/v2/category?organization_id=${organization_id()}`, payload);
  },
  editCategory(payload, id) {
    return Api().put(`/v2/category/${id}?organization_id=${organization_id()}`, payload);
  },

  // getAllCategories(filters, sort) {
  //   if (filters && filters.hasOwnProperty('archive') && filters.archive) {
  //     return Api().get(`/v2/category?archive=1&organization_id=${organization_id}`);
  //   } else if (filters && filters.hasOwnProperty('page')) {
  //     return Api().get(`/v2/category/page/${filters.page}?organization_id=${organization_id}`);
  //   } else return Api().get(`/v2/category?organization_id=${organization_id}`);
  // },

  getAllCategories(filters, sort) {
    if (filters && filters.hasOwnProperty('archive') && filters.archive) {
      return Api().get(`/v2/category/getProjectMainCategories/${filters.project_id}?archive=1&organization_id=${organization_id()}`);
    } else if (filters && filters.hasOwnProperty('page')) {
      return Api().get(`/v2/category/getProjectMainCategories/${filters.project_id}?${filters.page}&organization_id=${organization_id()}`);
    }
  },

  getProjectMainCategories(id) {
    return Api().get(`/v2/category/getProjectMainCategories/${id}?organization_id=${organization_id()}`);
  },

  getAllcategoriesByProject(id) {
    return Api().get(`/v2/category/project/${id}?organization_id=${organization_id()}`);
  },
  getCategory(payload, id) {
    return Api().get(`/v2/category/${id}?organization_id=${organization_id()}`, payload);
  },

  // SubCategory
  addSubCategory(payload) {
    return Api().post(`/v2/sub_category?organization_id=${organization_id()}`, payload);
  },
  editSubCategory(payload, id) {
    return Api().put(`/v2/sub_category/${id}?organization_id=${organization_id()}`, payload);
  },

  getAllSubCategories(filters, sort) {
    if (filters && filters.hasOwnProperty('archive') && filters.archive) {
      return Api().get(`/v2/category/singleDataByParent/${filters.categoryID}/${filters.project_id}?archive=1&organization_id=${organization_id()}`);
    } else if (filters && filters.hasOwnProperty('page')) {
      return Api().get(`/v2/category/singleDataByParent/${filters.categoryID}/${filters.project_id}?page_no=${filters.page}&organization_id=${organization_id()}`);
    }
  },

  getSubCategoriesByParentCategory(parent, project) {
    return Api().get(`v2/category/singleDataByParent/${parent}/${project}?organization_id=${organization_id()}`);
  },


  // NEW
  // getAllSubCategoriesByCategory(payload, filters, sort) {
  //   let url = `/v2/sub_category/category/${payload.categoryID}`;
  //   if (filters && filters.hasOwnProperty('archive') && filters.archive) url += `?archive=1`;
  //   return Api().get(url);
  // },
  //   // NEW END
  //   getSubCategory(id, payload) {
  //     return Api().get(`/v2/sub_category/${id}`, payload);
  //   },
  //   getSubCategoryByCategory(payload, filters, sort) {
  //     let url = `/v2/sub_category/category/${payload.categoryID}`;
  //     filters && filters.hasOwnProperty('archive') && filters.archive ? url += `?archive=1` : '';
  //     return Api().get(url);
  //     // OLD CODE
  //     // return Api().get(`/v2/sub_category/category/${payload.categoryID}`)
  //   },
  //   getByCategory(payload, id) {
  //     return Api().get(`/v2/sub_category/category/${id}`, payload);
  //   },
};
